import $ from 'jquery';
import _ from 'underscore';
import Swiper, { Navigation } from 'swiper'; Swiper.use([Navigation]);

import { uuidv4 } from './Encryption';
import { gSettings } from './Settings';

import { getWaypointBackgrounds, getWaypointPins } from './WaypointFilter';


import View from './View';

export class AddWaypointView extends View {
    constructor(waypointManager, modalView, lat, lon, existingWaypoint) {
        super();
        var self = this;
        this.waypointManager = waypointManager;
        this.modal = modalView;
        this.lat = lat;
        this.lon = lon;
        this.numImagesLoaded = 0;
        this.swiper = null;
        this.pendingImages = new Array();
        this.bExistingImagesChanged = false;
        this.waypoint = existingWaypoint;
        $('#inputFiles').on('change', (event) => { 
            this.getImageURLsFromAddWaypoint(event.target.files);
         });
         
        $('#modalPrimaryButton').removeAttr("disabled"); //enable save button.
        
        this.setupPinBackgroundPicker();
        this.setupPinPicker();

        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                var name = $("#addWaypointTitle").val();
                var desc = $("#addWaypointDescription").val();
                var pinImage = self.selectedPinName() + '.png';
                var backgroundImage = self.selectedBackgroundName() + '.png';

                if(!name || name.trim() == '') {
                    name = 'Untitled Waypoint';
                }

                if(!desc) {
                    desc = '';
                }

                self.saveAndClose(name, desc, pinImage, backgroundImage);
            }
        );

        this.updatePreviewPin();
        
        _.delay(function () {
            $('#'+self.selectedPinName())[0].scrollIntoView({behavior: "smooth"});
        },700);
    }

    setupPinPicker() {
        var arrayOfPinNames = getWaypointPins();
        var backgroundImage = this.getInitialBackgroundImage();
        var previouslySelectedPin = gSettings.getLastWaypointImage();
        for(var i = 0; i < arrayOfPinNames.length; i++) {
            var pinName = arrayOfPinNames[i];
            $("#pin-picker").append(this.getSourcelessDivForPin(pinName, pinName === previouslySelectedPin ? true : false)); 
            this.setPinImage(pinName, backgroundImage);
        }

        //add class .selected which will highlight it and remove class .selected from all others
        var self = this;
        $("#pin-picker .img-frame").click( function() {
            self.selectPinImageFromPinPicker($(this));
            return false;
        });
    }

    getInitialBackgroundImage() {
        return gSettings.getLastWaypointBackground();
    }

    updatePinBackgrounds() {
        var arrayOfPinNames = getWaypointPins();
        var backgroundImage = this.selectedBackgroundName();
        for(var i = 0; i < arrayOfPinNames.length; i++) {
            var pinName = arrayOfPinNames[i];
            this.setPinImage(pinName, backgroundImage);
        }
    }

    setupPinBackgroundPicker() {
        var arrayOfBackgroundNames = getWaypointBackgrounds();
        var previouslySelectedBackground = gSettings.getLastWaypointBackground();

        for(var i = 0; i < arrayOfBackgroundNames.length; i++) {
            var backgroundName = arrayOfBackgroundNames[i];
            $("#pin-background-picker").append(this.getSourcelessDivForPin(backgroundName, backgroundName === previouslySelectedBackground ? true : false)); 
            $("#"+backgroundName+"_pin_picker").attr('src', 'images/'+backgroundName+'@2x.png');
        }

        //add class .selected which will highlight it and remove class .selected from all others
        var self = this;
        $("#pin-background-picker .img-frame").click( function() {
            self.selectBackgroundImageFromPinPicker($(this));
            return false;
        });
    }

    setPinImage(pinName, backgroundImage) {
        this.waypointManager.cache.getWaypointImage(pinName,backgroundImage).then(function(pinUrl) {
            $("#"+pinName+"_pin_picker").attr('src', pinUrl);
        });
    }

    getSourcelessDivForPin(pinImageName, bSelected) {
        var selected = bSelected ? ' selected' : '';
        return '<div id="'+pinImageName+'" class="img-frame'+selected+'" style="display:inline-block"><img id="'+pinImageName+'_pin_picker" class="pin-image" style="max-width:40px; height:auto; width:auto; margin-top:2px"></div>';
    }

    setWaypointName(name) {
        if(name != null) {
            $("#addWaypointTitle").val(name);
        }
    }

    selectPinImageFromPinPicker(imgFrame) {
        this.removeSelectedFromAllPinImages();
        $(imgFrame).toggleClass("selected", true);
        this.updatePreviewPin();
    }

    selectBackgroundImageFromPinPicker(imgFrame) {
        this.removeSelectedFromAllBackgroundImages();
        $(imgFrame).toggleClass("selected", true);
        this.updatePreviewPin();
        this.updatePinBackgrounds();
    }
    
    removeSelectedFromAllPinImages() {
        var classesNodeList = document.querySelectorAll("#pin-picker .img-frame");
        Array.prototype.map.call(classesNodeList, function(element) {
            $(element).toggleClass("selected", false);
        });
    }

    removeSelectedFromAllBackgroundImages() {
        var classesNodeList = document.querySelectorAll("#pin-background-picker .img-frame");
        Array.prototype.map.call(classesNodeList, function(element) {
            $(element).toggleClass("selected", false);
        });
    }

    getImageURLsFromAddWaypoint(files) {
        this.numImagesLoaded = this.existingImages ? this.existingImages.length : 0;
       
        $('#AddWaypointView_swipeContainer').hide();   
        if (files && files[0]) {
            var totalNumberOfImages = this.numImagesLoaded + files.length;
            for(var i = 0; i < files.length; i++) {
    
                var newName = 'Image_' + uuidv4() + ".jpg"; //create a name with a uuid. This way we don't have to worry about bad characters, and unique names
                var initialFile = files[i];
                var blob = initialFile.slice(0,initialFile.size, 'image/jpg');
                var newFile = new File([blob], newName, {type: 'image/jpg'})
                this.pendingImages.push(newFile);
    
                var reader = new FileReader();
                reader.onload = (_.bind((file) => {
                    return _.bind((e) => {
                        this.addImageToCarousel(e.target.result, file.name, totalNumberOfImages, false);
                    }, this);
                }, this))(newFile);
    
                reader.readAsDataURL(newFile);
            }
        }
    }
    

    addImageToCarousel(source, filename, totalNumberOfImages, bExistingImage) {
        var id = filename.substring(0, filename.length - 4); //remove the extension
        var deleteId = id + "-close"; 
        // $('<div id="'+id+'" class="swiper-slide"><div class="img-wrapper"><div class="alignment-helper"></div><div class="img-container"><img class="swiper-img" src="'+source+'"><img id="'+deleteId+'" class="delete" src="images/close_button.png"></div></div></div>').appendTo('#AddWaypointSwiper');
        $('<div id="'+id+'" class="swiper-slide"><div class="img-wrapper"><div class="alignment-helper"></div><div class="img-container"><img class="swiper-img" src="'+source+'"></div><img id="'+deleteId+'" class="delete" src="images/close_button.png"></div></div>').appendTo('#AddWaypointSwiper');
        
        this.bindDeleteClickEvent("#"+deleteId, filename, bExistingImage);

        if(++this.numImagesLoaded == totalNumberOfImages) {
            this.checkAndCreateCarousel();
        }
    }

    bindDeleteClickEvent(id, filename, bExistingImage) {
        if(!bExistingImage) {
            $(id).click( _.bind(function() {
                this.removePendingImageWithName(filename);
                return false;
            },this));
        } else {
            $(id).click( _.bind(function() {
                this.removeExistingImageWithName(filename);
                return false;
            },this));
        }
    }

    checkAndCreateCarousel() {
        $('#AddWaypointView_swipeContainer').show();
    
        //we need unique classes for each visible swiper (so in the case where we have the right view and modal, it causes an issue)
        this.swiper = new Swiper ('.swiper-container-modal', {
                // Optional parameters
                slidesPerView: this.getNumberOfSlidesPerView(),
                spaceBetween: 0,
                navigation: { //nav arrows
                    nextEl: '.swiper-button-next-modal',
                    prevEl: '.swiper-button-prev-modal',
                },
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
            });

    }
    
    removePendingImageWithName(filename) {
        for(var i = 0; i < this.pendingImages.length; i++) {
            var file = this.pendingImages[i];
            if(file.name == filename) {
                this.pendingImages.splice(i,1);
                $('#'+filename.substring(0,file.name.length-4)).remove();
                this.swiper.update();
                break;
            }
        }
    }

    removeExistingImageWithName(filename) {
    }
    
    
    getNumberOfSlidesPerView() {
        //width of container divided by max image width (smallest whole number)
        return Math.floor(($('#AddWaypointView_swipeContainer').width()) / 290); 
    }

    saveAndClose(name, desc, pinImage, backgroundImage) {

        this.waypointManager.addWaypoint(name, desc, this.lat, this.lon, pinImage, backgroundImage, this.pendingImages);
        // var uuid = uuidv4();
        // if(this.pendingImages && this.pendingImages.length > 0) {
        //     gFirebaseHelper.saveImagesToFirebase(this.pendingImages, uuid);
        // }

        // var imageNames = ""; 
        // if(this.pendingImages) {
        //     for(var i = 0; i < this.pendingImages.length; i++) {
        //         imageNames += (imageNames == "" ? this.pendingImages[i].name : "," + this.pendingImages[i].name);
        //     }
        // }

        // gFirebaseHelper.setLastWaypointImage(pinImage.replace(".png", ""), backgroundImage.replace(".png", ""));
        // gFirebaseHelper.addWaypoint(uuid, name, desc, this.lat, this.lon, pinImage, backgroundImage, imageNames);
    }

    updatePreviewPin() {
        var selectedPinName = this.selectedPinName();
        var selectedBackgroundName = this.selectedBackgroundName();
        this.waypointManager.cache.getWaypointImage(selectedPinName,selectedBackgroundName).then(function(pinUrl) {
            $("#preview_pin").attr('src', pinUrl);
        });
    }

    selectedPinName() {
        var pinImageName = $("#pin-picker .img-frame.selected").first().attr("id");
        return pinImageName == null ? "camp" : pinImageName;
	}

	selectedBackgroundName() {
		var backgroundImageName = $("#pin-background-picker .img-frame.selected").first().attr("id");
        return backgroundImageName == null ? "ihunter_pin_background_green" : backgroundImageName;
	}
}

export default AddWaypointView;
