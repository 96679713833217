import $ from 'jquery';
import L from 'leaflet';
import bootbox from 'bootbox';
import moment from 'moment';

import { MESSAGE_TYPE_WAYPOINT, MESSAGE_TYPE_NOTIFICATION, MESSAGE_TYPE_CURRENT_LOCATION, MESSAGE_TYPE_BROADCAST_LOCATION } from './FirebaseAttributes';
import { gWaypointImageCache } from './WaypointImageCache';
import { openHTMLFileInCustomModal } from './ModalHelper';
import { getBackgroundResourceForPinImage } from './Waypoint';

import View from './View';
import DateHelpers from './DateHelpers';
import AddContactsView from './AddContactsView';
import UnknownContact from './UnknownContact';
import SearchResult from './SearchResult';
import Rect from './Rect';

const CHAT_MESSAGE_TYPE_TOP = "top";
const CHAT_MESSAGE_TYPE_INTERIOR = "interior";
const CHAT_MESSAGE_TYPE_BOTTOM = "bottom";
const CHAT_MESSAGE_TYPE_SOLO = "solo";

export class ChatView extends View {
    constructor(chatManager) {
        super();

        this.chatManager = chatManager;
        this.activeChat = null;

        this.chatManager.contactManager.onUpdateContactRequest(() => {
            this.refreshChats();
        });

        this.chatManager.onUpdateContact((contact) => {
            this.refreshChats();
        });


        // this.chatManager.onUpdateContactImage(() => {
        //     this.refreshChats();
        // });

        this.chatManager.contactManager.onUpdateContactImage((contact) => {
            this.replaceInnerHTMLForChatRow(this.chatManager.chatForChatKey(contact.chatKey));
        });

        // this.chatManager.onUpdateMessages((chat) => {
        //     this.getMessagesForChat(chat, true);
        // });


        this.setupWindowListeners();
    }

    setupWindowListeners() {

        window.bootbox = bootbox; // To support bootbox.alert in the HTML below

        window.acceptContact = (uid) => {
            this.activeChat = this.chatManager.acceptContact(uid);
        };

        window.denyContact = (uid) => {
            this.chatManager.denyContact(uid);
        };

        window.selectChat = (id) => {
            this.selectChat(id);
        };

        window.sendChatMessage = () => {
            this.sendChatMessage();
        };

        window.onTextAreaKeyPress = (e) => {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code == 13) { //Enter keycode
                e.preventDefault();
                this.sendChatMessage();
            }
        };
        
        window.onAddContactClick = () => {
            openHTMLFileInCustomModal('Add Contacts', 'add_contacts_view.html', 'Save', 'Cancel', '40', '80', false).then((result) => {
                new AddContactsView(result, this.chatManager.contactManager);
            }, function(err) {
                console.log(err);
            });
        };

        window.showAlertForPendingOutgoingRequest = (name, uid) => {
            this.showAlertForPendingOutgoingRequest(name, uid);
        };
    }

    populatePage() {
        this.getChats();
        this.setupScrollListener();

        this.hideBottomChatBars(); // for now lets hide them on startup...
        $('#rightSidebarSpinner').hide();
        this.chatManager.logEventChatDrawerViewed();
    }
    setupScrollListener() {
        $('#chat-messages-scrollable-div').scroll(() => {
            if (this.scrollTop == 0) {
                // alert("top");
                this.chatManager.preloadMessages(this.activeChat);
            } 
        });
    }

    onMessagesScroll() {

    }

    refreshChats() {
        this.getChats();
        if(this.activeChat) {
            this.selectChat(this.activeChat);
        }
    }

    getChats() {
        $("#contacts-panel-content").html('');
        var html = "";
        if(this.requestsExist()) {
            html += this.getHTMLForChatHeader("Requests");
            var incomingCount = this.chatManager.contactManager.incomingRequests.length;
            for(let i = 0; i < incomingCount; i++) {
                let contact = this.chatManager.contactManager.incomingRequests[i];
                html += this.getHTMLForPendingIncomingRequest("", contact);
            }

            var outgoingCount = this.chatManager.contactManager.outgoingRequests.length;
            for(let i = 0; i < outgoingCount; i++) {
                let contact = this.chatManager.contactManager.outgoingRequests[i];
                html += this.getHTMLForPendingOutgoingRequest(contact.photoUrl, contact.username, contact.email, contact.uid);
            }
        }
        if(this.requestsExist() && this.chatsExist()) {
            html += this.getHTMLForChatHeader("Chats");
        }

        if(this.chatsExist()) {
            html += '<div id="active-chats-container">'; //we'll use this container to reorder?

            var chatCount = this.chatManager.chats.length;
            for(let i = 0; i < chatCount; i++) {
                var chat = this.chatManager.chats[i];
                html += this.getHTMLForChatRow(chat);
            }

            html += '</div>';
        }

        $("#contacts-panel-content").append(html);
    }

    requestsExist() {
        return this.pendingOutgoingRequestsExist() || this.pendingIncomingRequestsExist();
    }

    pendingOutgoingRequestsExist() {
        return this.chatManager.contactManager.outgoingRequests.length > 0;
    }

    pendingIncomingRequestsExist() {
        return this.chatManager.contactManager.incomingRequests.length > 0;
    }

    chatsExist() {
        return this.chatManager.chats.length > 0;
    }

    selectChat(chatkey) {
        // console.log("select chat with key:" + id);
        this.deselectAllChats();
        $("#chat-panel").toggleClass("active", true);
        $("#"+chatkey).toggleClass("selected", true);
        var chat = this.chatManager.chatForChatKey(chatkey);
        if(chat) { 
            this.chatManager.setLastViewed(chat);
            chat.clearUnreadMessages();
            this.showBottomChatBars();
            $("#chat-panel-heading").html(chat.name);
            this.populateSettingsButtonForChat(chat);
            this.getMessagesForChat(chat, false);
            this.activeChat = chat.chatkey;
        }
    }

    populateSettingsButtonForChat(chat) {
        $("#chatSettingsButton").show();
        $("#chatSettingsPopup").html("");

        if(chat.isGroupChat()) {
            $("#chatSettingsPopup").append('<div id="editGroupChat" class="popup-border-bottom popup-row-div top">Group Info</div>');
            $("#chatSettingsPopup").append('<div id="addContactToGroup" class="popup-border-bottom popup-row-div">Add Contact</div>');
            $("#chatSettingsPopup").append('<div id="clearChat" class="popup-border-bottom popup-row-div">Clear Chat</div>');
            $("#chatSettingsPopup").append('<div id="leaveGroupChat" class="popup-row-div bottom">Leave Group</div>');

            $("#editGroupChat").click(() => {
                window.onEditGroupChat(chat); //index.js
            });
            $("#addContactToGroup").click(() => {
                window.onAddContactToGroupClick(chat);
            });
            $("#clearChat").click(() => {
                this.clearChat(chat);
            });
            $("#leaveGroupChat").click(() => {
                this.leaveGroupChat(chat);
            });
        } 
        else {
            $("#chatSettingsPopup").append('<div id="clearChat" class="popup-border-bottom popup-row-div top">Clear Chat</div>');
            $("#chatSettingsPopup").append('<div id="deleteContact" class="popup-row-div bottom">Delete Contact</div>');
            $("#clearChat").click(() => {
                this.clearChat(chat);
            });
            $("#deleteContact").click(() => {
                this.deleteContactForChat(chat);             
            });
        }

        $("#chatSettingsButton").off("click");
        $("#chatSettingsButton").click(() => {
            this.toggleChatSettingsPopup();
        });
    }

    leaveGroupChat(chat) {
        var self = this;
        bootbox.confirm({
            message: "Are you sure that you want to leave this group?",
            onEscape: false,
            closeButton: false,
            buttons: {
                confirm: {
                    label: 'Leave Group',
                    className: 'btn-danger'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-secondary'
                }
            },
            callback: (result) => {
                if(result) {
                    this.chatManager.leaveGroupChat(chat);
                    self.refreshChats(); //may need to delay or use promise
                } 
            }
        });
    }

    deleteContactForChat(chat) {
        var self = this;
        bootbox.confirm({
            message: "Are you sure that you want to delete this contact?",
            onEscape: false,
            closeButton: false,
            buttons: {
                confirm: {
                    label: 'Delete Contact',
                    className: 'btn-danger'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-secondary'
                }
            },
            callback: (result) => {
                if(result) {
                    this.chatManager.deleteContactForChat(chat);
                    self.refreshChats(); //may need to delay or use promise
                } 
            }
        });   
    }

    clearChat(chat) {
        var self = this;
        bootbox.dialog({
            message: "Are you sure that you want to clear this chat?",
            onEscape: false,
            closeButton: false,
            buttons: {
                confirm: {
                    label: 'Clear Chat',
                    className: 'btn-danger',
                    callback: function(){
                        $("#chatSettingsButton").off("click");
                        self.chatManager.clearChat(chat);
                        self.populateSettingsButtonForChat(chat);
                        self.getMessagesForChat(chat, false);        
                    }
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-secondary',
                    callback: function(){
                                
                    }
                }
            }
        }); 
    }

    toggleChatSettingsPopup() {
        var popup = document.getElementById("chatSettingsPopup");
        popup.classList.toggle("show");
    }

    hideChatSettingsPopup() {
        var popup = document.getElementById("chatSettingsPopup");
        popup.classList.remove("show");
    }

    deselectAllChats() {
        $(".contact-row").toggleClass("selected", false);
        $("#chatSettingsButton").hide();
        $("#chat-panel-heading").html("");
        this.activeChat = "";
        $("#chat-panel").toggleClass("active", false);
        this.resetChatPanelContent();
        $("#chat-buttons-div").hide();
        $("#chat-input-div").hide();
        this.hideChatSettingsPopup();
    }

    sendChatMessage() {
        var textarea = $("#chat-input-textarea");
        if(this.activeChat && textarea && textarea.val().trim() !== "") {

            this.chatManager.sendMessage(textarea.val().trim(), this.activeChat);
            textarea.val('');
        }
    }

    reorderChatDivs() {
        //check if the chat view is even visible
        if($("#chats-div").length) {
            var chatsLength = this.chatManager.chats.length;
            for(var i = 0; i < chatsLength; i++) {
                var chat = this.chatManager.chats[i];
                var elementAtIndex = $('#active-chats-container').children('.contact-row').eq(i);
                
                if(chat.chatkey !== elementAtIndex.attr('id')) {
                    $('#'+chat.chatkey).insertBefore(elementAtIndex);
                }
            }
        }
    }

    hideBottomChatBars() {
        $("#chat-buttons-div").hide();
        $("#chat-input-div").hide();
    }

    showBottomChatBars() {
        $("#chat-buttons-div").show();
        $("#chat-input-div").show();
    }

    getMessagesForChat(chat, bScrollTop) {

        chat.sortMessages();
        this.resetChatPanelContent();
        var div = "#chat-messages-div";
    
        var messagesLength = chat.messages.length;
        for(var i = 0; i < messagesLength; i++) {
            var userBefore = i==0 ? null : chat.messages[i-1].contactUID;
            var message = chat.messages[i];
            var sender = this.chatManager.getContactForUID(message.contactUID);


            if(message.type == MESSAGE_TYPE_NOTIFICATION) {
                $(div).append(this.getHTMLForNotification(message));
            } 
            else {
                var userAfter = i==messagesLength-1 ? null : chat.messages[i+1].contactUID;
                if(message.contactUID === userBefore && message.contactUID === userAfter) {
                    //interior
                    if(message.contactUID === this.chatManager.user.uid) {
                        $(div).append(this.getHTMLForOutgoingMessage(CHAT_MESSAGE_TYPE_INTERIOR, this.chatManager.getUserAsContact().photoUrl, message));
                    } else {
                        $(div).append(this.getHTMLForIncomingMessage(CHAT_MESSAGE_TYPE_INTERIOR, sender.photoUrl, message));
                    }
                } 
                else if(message.contactUID === userBefore && message.contactUID !== userAfter) {
                    //bottom
                    if(message.contactUID === this.chatManager.user.uid) {
                        $(div).append(this.getHTMLForOutgoingMessage(CHAT_MESSAGE_TYPE_BOTTOM, this.chatManager.getUserAsContact().photoUrl, message));
                    } else {
                        $(div).append(this.getHTMLForIncomingMessage(CHAT_MESSAGE_TYPE_BOTTOM, sender.photoUrl, message));
                    }
                } 
                else if(message.contactUID !== userBefore && message.contactUID === userAfter) {
                    //top
                    if(message.contactUID === this.chatManager.user.uid) {
                        $(div).append(this.getHTMLForOutgoingMessage(CHAT_MESSAGE_TYPE_TOP, this.chatManager.getUserAsContact().photoUrl, message));
                    } else {
                        $(div).append(this.getHTMLForIncomingMessage(CHAT_MESSAGE_TYPE_TOP, sender.photoUrl, message));
                    }
                } 
                else {
                    //solo
                    if(message.contactUID === this.chatManager.user.uid) {
                        $(div).append(this.getHTMLForOutgoingMessage(CHAT_MESSAGE_TYPE_SOLO, this.chatManager.getUserAsContact().photoUrl, message));
                    } else {
                        $(div).append(this.getHTMLForIncomingMessage(CHAT_MESSAGE_TYPE_SOLO, sender.photoUrl, message));
                    }
                }
                
                this.createClickListeners(message);

            }
        }
    
        if(bScrollTop) {
            this.scrollMessagesToTop();
        } else {
            this.scrollMessagesToBottom();
        }
    }

    createClickListeners(message) {
        //add click listener if message.type is waypoint!
        if(message.type === MESSAGE_TYPE_WAYPOINT && message.contactUID !== this.chatManager.user.uid) {
            let divID = '[id="'+message.messageUID.replace(" ","")+'"]';
            
            $(divID).click({msg:message}, (event) => {
                var m = event.data.msg;
                var arrayOfWaypointUUIDs = m.text.split(',');
                arrayOfWaypointUUIDs.forEach((waypointUUID, index) => {
                    if(this.chatManager.waypointExists(waypointUUID)) {
                        this.chatManager.acceptClipboardWaypoint(waypointUUID, m.contactUID);
                        this.zoomToBoundsStringFromClipboard(m.additionalInfo);
                        //acceptClipboardWaypoint
                        //zoom to bounds
                        //dismiss drawer?
                    } 
                    else {
                        this.chatManager.copyFromClipboard(waypointUUID,m.contactUID);
                        this.zoomToBoundsStringFromClipboard(m.additionalInfo);
                        //copyFromClipboard(waypointUUID, message.uid);
                        //zoom to bounds
                        //dismiss drawer?
                    }
                }); 
                this.chatManager.logEventWaypointsCopiedFromClipboard(this.activeChat, arrayOfWaypointUUIDs);                     
            });
        } 
        else if(message.type === MESSAGE_TYPE_CURRENT_LOCATION) {
            let divID = '[id="'+message.messageUID.replace(" ","")+'"]';
            $(divID).click({msg:message}, (event) => {
                var m = event.data.msg;

                var coords = m.text.split(",");
                var lat = parseFloat(coords[0]);
                var lon = parseFloat(coords[1]);
                var sr = new SearchResult(null, "Unknown Name");
                sr.location = new L.LatLng(lat,lon);

                var chat = this.chatManager.chatForChatKey(this.activeChat);
                for(var i = 0; i < chat.activeParticipants.length; i++) {
                    var contact = chat.activeParticipants[i];
                    if(contact.uid === m.contactUID) {
                        // console.log(m);
                        sr.name = contact.username + " (" + DateHelpers.relativeStringFromDate(moment(m.time)) + ")";
                        break;
                    }
                }
                this.chatManager.appView.map.addMarkerForSearchResult(sr);
                this.chatManager.appView.map.zoomToLocation(sr.location);
            });
        } 
        else if(message.type === MESSAGE_TYPE_BROADCAST_LOCATION) {
            let divID = '[id="'+message.messageUID.replace(" ","")+'"]';
            $(divID).click({msg:message}, async (event) => {
                // Contact contact = getFirebaseHelper().contactForUID(message.contactUID);
                // getFirebaseHelper().zoomToNextAddedBroadcastedLocation = true;
                // getFirebaseHelper().acceptBroadcastedLocationFromContactUID(contact.uid);
                // iHunterAct.dismissChatDrawer();
                //var contact = this.chatManager.getContactForUID(message.contactUID);
                
                try{
                    await this.chatManager.contactManager.acceptBroadcastedLocationFromContactUID(message.contactUID);

                }catch(error) {
                    bootbox.alert("This invitation has expired. Your contact must invite you again to share their location.");
                }
            }); 
        }
    }
    
    getBoundingRectFromStringFromClipboard(boundsString) {
        var coords = boundsString.split(",");
        //left, top, right, bottom
        var lat = parseFloat(coords[0]);
        var lon = parseFloat(coords[1]);
        var latSpan = parseFloat(coords[2]);
        var lonSpan = parseFloat(coords[3]);
        
        //left, top, right bottom
        var rect = new Rect(lon - lonSpan, lat + latSpan, lon + lonSpan, lat - latSpan)
        return rect; 
    }

    zoomToBoundsStringFromClipboard(boundsString) {
        var boundsRect = this.getBoundingRectFromStringFromClipboard(boundsString);
        this.chatManager.appView.zoomToLatLngBoundsRect(boundsRect);
    }

    scrollMessagesToBottom() {
        var msgsDiv = document.getElementById("chat-messages-scrollable-div");
        if(msgsDiv) {
            msgsDiv.scrollTop = msgsDiv.scrollHeight;
        }
    }

    scrollMessagesToTop() {
        var msgsDiv = document.getElementById("chat-messages-scrollable-div");
        if(msgsDiv) {
            msgsDiv.scrollTop = 0;
        }
    }


    resetChatPanelContent() {

        let chatTextArea = $("#chat-input-textarea");
        let chatText = chatTextArea ? chatTextArea.val() : "";

        //btn btn-dark-bordered-selection
        var html = '<div id="chat-messages-scrollable-div"><div id="chat-messages-div"></div></div>';
        html += '<div id="chat-buttons-div"><button id="shareWaypoint" class="btn btn-primary text-center" type="button" onclick="onShareWaypointClick()">Share Waypoint</button></div>';
        html += '<div id="chat-input-div">';
        html += '<textarea id="chat-input-textarea" class="autoExpand" rows="1" data-min-rows="1" placeholder="" onkeypress="onTextAreaKeyPress(event, this);"></textarea>'
        html += '<button id="sendMessageButton" class="btn btn-dark-bordered-selection text-center" type="button" onClick="sendChatMessage()">';
        html += '<i class="ihunter-icon-send d-xl-flex align-items-xl-end"></i>';
        html += '</button>';
        html += '</div>';
        $("#chat-panel-content").html(html);

        chatTextArea = $("#chat-input-textarea");
        chatTextArea.val(chatText);

    
        var autoExpand = document.querySelectorAll(':scope textarea.autoExpand');
        function debounced(e){
            // console.log("debounced");
            e.target.style.height = e.target.scrollHeight + 'px';
        }
        autoExpand.forEach(function(el){
            el.addEventListener('input', debounced);
        });
    }

    getHTMLForChatRow(chat) {
        var chatKey = chat.chatkey;    
        var htmlContent = '<div class="contact-row" id="'+chatKey+'" onClick="selectChat(\''+chatKey+'\')"' + '">';
        htmlContent += this.getInnerHTMLForChatRow(chat);
        htmlContent += '</div>';
        return htmlContent;
    }

    replaceInnerHTMLForChatRow(chat) {
        $("#"+chat.chatkey).html(this.getInnerHTMLForChatRow(chat));
    }


    getInnerHTMLForChatRow(chat) {
        var leftImageSrc = chat.getChatPictureUrl();
        var title = chat.name;
        var subTitle = chat.lastMessage;
        var dateString = DateHelpers.relativeStringFromDate(DateHelpers.dateFromString(chat.lastMessageDate));

        var tempNotification = chat.numNewMessages > 0 ? '<div class="notificationCircle">'+chat.numNewMessages+'</div>' : '';
        var htmlContent = '<div class="left-div"> <img class="pin-image" src="' + leftImageSrc + '" /> </div>';
        htmlContent += '<div class="middle-div">';
        htmlContent += '<div class="ihunter-menu-text medium top">'+ title + tempNotification + '</div>'; //remove temp section!
        htmlContent += '<div class="bottom-div">';
        htmlContent += '<div class="ihunter-menu-text small left">' + subTitle + '</div>';
        htmlContent += '<div class="ihunter-menu-text align-right small right">' + dateString + '</div>';
        htmlContent += '</div>';
        htmlContent += '</div>';

        return htmlContent;
    }

    getHTMLForChatHeader(title) {
        var htmlContent = '<div class="contacts-header-row">';
        htmlContent += '<div>' + title + '</div>';
        htmlContent += '</div>';
        return htmlContent;
    }

    getHTMLForPendingOutgoingRequest(leftImageSrc, name, subTitle, uid) {
        var htmlContent = '<div class="contact-row" onClick="showAlertForPendingOutgoingRequest(\'' + name + '\',\'' + uid + '\')">';
        htmlContent += '<div class="left-div"> <img class="pin-image" src="' + leftImageSrc + '" /> </div>';
        htmlContent += '<div class="middle-div">';
        htmlContent += '<div class="ihunter-menu-text medium top">'+ name + ' (Requested)</div>';
        htmlContent += '<div class="bottom-div">';
        htmlContent += '<div class="ihunter-menu-text small left">' + subTitle + '</div>';
        htmlContent += '</div>';
        htmlContent += '</div>';
        htmlContent += '</div>';
        return htmlContent;
    }

    showAlertForPendingOutgoingRequest(name, uid) {
        // var bootboxAlert = bootbox.alert(''+name+' has been notified of your contact request. Once they accept the request you will be able to chat, send waypoints, and share your location.')
        var self = this;
        bootbox.dialog({
            message: ''+name+' has been notified of your contact request. Once they accept the request you will be able to chat, send waypoints, and share your location. \n\n Would you like to remove this request?',
            onEscape: true,
            closeButton: false,
            buttons: {  
                cancel: {
                    label: 'Cancel',
                    className: 'btn-cancel',
                    callback: function(){
                        
                    }
                },
                confirm: {
                    label: 'Remove',
                    className: 'btn-danger',
                    callback: function(){
                        self.chatManager.removeOutgoingContactRequest(uid)
                    }
                }
            }
        });
    }

    getHTMLForPendingIncomingRequest(onRowClick, contact) {

        var htmlContent = '<div class="contact-row" onClick="' + onRowClick + '">';
        htmlContent += '<div class="left-div"> <img class="pin-image" src="' + contact.photoUrl + '" /> </div>';
        htmlContent += '<div class="middle-div request">';
        htmlContent += '<div class="ihunter-menu-text medium top">'+ contact.username + '</div>';
        htmlContent += '<div class="bottom-div">';
        htmlContent += '<div class="ihunter-menu-text small left">' + contact.email + '</div>';
        htmlContent += '</div>';
        htmlContent += '</div>';
        htmlContent += '<div class="right reject-div" onClick="denyContact(\''+contact.uid+'\')"><img src="images/x_white@2x.png" /></div>';
        htmlContent += '<div class="right accept-div" onClick="acceptContact(\''+contact.uid+'\')"><img src="images/checkmark_white@2x.png" /></div>';
        htmlContent += '</div>';
        return htmlContent;
    }


    getHTMLForIncomingMessage(messageStyle, imgSource, message) {
        var src = imgSource != "" ? imgSource : "images/noProfilePic.jpg";
        var bShouldShowImage = messageStyle === CHAT_MESSAGE_TYPE_BOTTOM || messageStyle === CHAT_MESSAGE_TYPE_SOLO;
        var htmlContent = '';

        if(messageStyle === CHAT_MESSAGE_TYPE_SOLO || messageStyle === CHAT_MESSAGE_TYPE_TOP) {
            htmlContent += this.getHTMLForUsernameAboveMessage(message);
        }

        htmlContent += '<div class="message-row incoming '+messageStyle+'" id="'+message.messageUID.replace(" ","")+'" >';
            htmlContent += bShouldShowImage ? '<div class="left"><img src="' + src + '" /></div>' : '<div class="left"></div>';
            htmlContent += '<div class="middle">';
                htmlContent += bShouldShowImage ? '<div class="chat-message-callout incoming"></div>' : '';
                htmlContent += '<div class="colored-div '+messageStyle+'">';
                    htmlContent += this.getMessageContent(message, true);
                htmlContent += '</div>';
            htmlContent += '</div>';
            htmlContent += '<div class="right"></div>';
        htmlContent += '</div>';

        return htmlContent;
    }

    //MESSAGE_TYPE_WAYPOINT
    //MESSAGE_TYPE_CURRENT_LOCATION
    //MESSAGE_TYPE_BOARDCAST_LOCATION
    getHTMLForOutgoingMessage(messageType, imgSource, message) {
        var src = imgSource != "" ? imgSource : "images/noProfilePic.jpg"; 
        var bShouldShowImage = messageType === CHAT_MESSAGE_TYPE_BOTTOM || messageType === CHAT_MESSAGE_TYPE_SOLO;

        var htmlContent = '<div class="message-row outgoing '+messageType+'" id="'+message.messageUID.replace(" ","")+'" >';
        htmlContent += '<div class="left"></div>';
        htmlContent += '<div class="middle">';
        htmlContent += bShouldShowImage ? '<div class="chat-message-callout outgoing"></div>' : '';
            htmlContent += '<div class="colored-div '+messageType+'">';
                //htmlContent += '<div class="ihunter-menu-text medium">'+ message.text + '</div>';
                htmlContent += this.getMessageContent(message, false);
            htmlContent += '</div>';

        htmlContent += '</div>';
        htmlContent += bShouldShowImage ? '<div class="right"><img src="' + src + '" /></div>' : '<div class="right"></div>';
        htmlContent += '</div>';

        return htmlContent;
    } 

    getMessageContent(message, bIsIncoming) {
        var htmlContent = '';

        if(message.type === MESSAGE_TYPE_WAYPOINT) {
            var backgroundResource = message.pinBackgroundResource.length > 0 ? message.pinBackgroundResource : getBackgroundResourceForPinImage(message.pinResource);
            backgroundResource = backgroundResource.replace(".png","");
            var pinResource = message.pinResource.replace(".png","");

            htmlContent += '<img class="'+pinResource+'_'+backgroundResource+'" src="images/' + pinResource + '.png" style="margin-right:3px; width:25px"/>'; 
            var stringArray = message.text.split(",");
            var messageText = stringArray.length <= 1 ? "I want to share a waypoint with you. Tap here to add it." : "I want to share "+stringArray.length+" waypoints with you. Tap here to add them.";
            htmlContent += '<div class="ihunter-menu-text medium">'+ messageText + '</div>';

            gWaypointImageCache.getWaypointImage(pinResource, backgroundResource).then(function(imgUrl) {
                $('.'+pinResource+'_'+backgroundResource).attr('src', imgUrl);
            });
        }  
        else if(message.type === MESSAGE_TYPE_CURRENT_LOCATION) {
            htmlContent += '<img src="images/default_pin.png" style="margin-right:3px; width:25px"/>'; 
            htmlContent += '<div class="ihunter-menu-text medium">Tap here to view my current location: '+message.text+'</div>';
        }
        else if(message.type === MESSAGE_TYPE_BROADCAST_LOCATION) {
            var sender = this.chatManager.getContactForUID(message.contactUID);
            htmlContent += sender.locationMarker.getBroadcastPinImage(sender.photoUrl, true);
            htmlContent += '<div class="ihunter-menu-text medium">'+message.text+'</div>';
        }  
        else {
            htmlContent += '<div class="ihunter-menu-text medium">'+ message.text + '</div>';
        }
        var incoming = bIsIncoming ? 'incoming' : '';
        htmlContent += '<div class="ihunter-menu-text small chat-timestamp '+incoming+'">'+ DateHelpers.chatMessageStringFromDate(message.time) + '</div>';

        return htmlContent;
    }

    getHTMLForUsernameAboveMessage(message) {
        var uid = message.contactUID;
        var contact = this.chatManager.getContactForUID(uid);
        if(!contact) {
            contact = new UnknownContact(uid);
        }
        return '<div class="username-above-message">' + contact.username + '</div>';
    }

    getHTMLForNotification(message) {
        var htmlContent = '<div class="notification-row" >';
            htmlContent += '<div class="colored-div">';
            htmlContent += '<div class="ihunter-menu-text medium">'+ message.text + '</div>';
            htmlContent += '</div>';
        htmlContent += '</div>';
        return htmlContent;
    }

    updateGroupChatName(chat, name) {
        chat.name = name;
        $("#chat-panel-heading").html(chat.name);
        this.replaceInnerHTMLForChatRow(chat);
    }
}


// function getMessagesForChat(chat, bScrollTop) {
//     if(gChatView) {
//         gChatView.getMessagesForChat(chat, bScrollTop);
//     }
// }


// function waypointExists(uuid) {
//   return gMap.waypointsDict[uuid] || gMap.drawnWaypointsDict[uuid] || gMap.trackedWaypointsDict[uuid];    
// }

export default ChatView;
