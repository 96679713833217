import $ from 'jquery';

import { gSettings, SETTINGS_WAYPOINT_SORT_TYPE_NAME, SETTINGS_WAYPOINT_SORT_TYPE_DATE, SETTINGS_WAYPOINT_SORT_TYPE_LOCATION } from './Settings';
import { FB_WAYPOINT_SORT_TYPE } from './FirebaseAttributes';

import View from './View';

export class ShareWaypointsView extends View {
    constructor(modalView, chat, waypointManager, chatManager) {
        super();
        this.waypointManager = waypointManager;
        this.chatManager = chatManager;
        var self = this;
        this.chat = chat;
        this.selectedWaypointUUIDs = new Array();
        this.waypoints = new Array();
        this.modal = modalView;
        
        this.modal.find('#modalPrimaryButton').attr("disabled", true); //disable save button to begin with.
    
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                self.saveAndClose();
            }
        );

        this.refreshWaypoints();

        $('#selectAllSwitch').click(function() {
            if(this.checked) {
                self.onSelectAll();
            } else {
                self.onDeselectAll();
            }
            
        });

        $("#sortSegmentedGroup").click(function (event) {
            $("li", this)
            .removeClass("selected")
            .filter(event.target)
            .addClass("selected");
            
            var id = $("li", this).filter(event.target).attr('id');
            if(id == 'sortWaypointsByName') {
                gSettings.settingsManager.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_NAME);
            } 
            else if(id == 'sortWaypointsByDate') {
                gSettings.settingsManager.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_DATE);
            } 
            else {
                gSettings.settingsManager.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_LOCATION);
            }
            self.refreshWaypoints();
        });
    }

    refreshWaypoints() {
        this.populateWaypoints();
        this.appendWaypointsToHTML();
        this.setSwitchSelections();
    }

    populateWaypoints() {
        this.waypoints = this.waypointManager.getAllWaypoints();
        this.setInitialSortSelection();
    }

    appendWaypointsToHTML() {
        $("#availableWaypoints").html("");
        var self = this;
        if(this.waypoints) {
            var length = this.waypoints.length;
            for (var i = 0; i < length; i++) {
                var waypoint = this.waypoints[i];
                var html = waypoint.getHTMLForWaypointRowWithSwitch(false, waypoint.uuid + '_select');
                $("#availableWaypoints").append(html);

                $('#'+waypoint.uuid + '_select').click(function() {
                    var id = $(this).attr('id');
                    var uuid = id.substring(0, id.length - 7);
                    self.onSelectWaypointClicked(this, uuid);
                    self.checkSaveEnabled();
                });
            }
        }
    }

    setInitialSortSelection() {
        var sortType = gSettings.getPreference(FB_WAYPOINT_SORT_TYPE);
        if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_NAME) {
            this.waypointManager.sortWaypointsArrayByName(this.waypoints);
            $("#sortSegmentedGroup").find("li").removeClass("selected");
            $("#sortWaypointsByName").addClass("selected");
        } 
        else if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_DATE) {
            this.waypointManager.sortWaypointsArrayByDate(this.waypoints);
            $("#sortSegmentedGroup").find("li").removeClass("selected");
            $("#sortWaypointsByDate").addClass("selected");
        } 
        else if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_LOCATION) {
            this.waypointManager.sortWaypointsArrayByLocation(this.waypoints);
            $("#sortSegmentedGroup").find("li").removeClass("selected");
            $("#sortWaypointsByLocation").addClass("selected");
        }
    }


    saveAndClose() {
        if(this.selectedWaypointUUIDs && this.selectedWaypointUUIDs.length > 0) {
            var firstWaypoint = this.getWaypointWithUUID(this.selectedWaypointUUIDs[0]);
            if(firstWaypoint != null) {
                this.waypointManager.shareWaypoints(this.selectedWaypointUUIDs, this.chat, firstWaypoint.pinImage);
                this.chatManager.logEventWaypointsSharedToClipboard(this.chat, this.selectedWaypointUUIDs);
            }
        }
    }

    //needed because we're recreating the rows. This will re-select all of the switches that were already selected
    setSwitchSelections() {
        if(this.selectedWaypointUUIDs && this.selectedWaypointUUIDs.length >0) {
            if(this.waypoints) {
                var selectedWaypointsLength = this.selectedWaypointUUIDs.length;
                for (var k = 0; k < selectedWaypointsLength; k++) {
                    var uuid = this.selectedWaypointUUIDs[k];
                    
                    var waypointsLength = this.waypoints.length;
                    for (var i = 0; i < waypointsLength; i++) {
                        var waypoint = this.waypoints[i];
                        if(uuid == waypoint.uuid) {
                            $('#'+waypoint.uuid + '_select').prop('checked', true);
                            break;
                        }
                    }
                }
            }
        }
    }

    getWaypointWithUUID(uuid) {
        var length = this.waypoints.length;
        for(var i = 0; i < length; i++) {
            var wp = this.waypoints[i];
            if(wp.uuid === uuid) {
                return wp;
            }
        }
        return null;
    }

    onSelectWaypointClicked(checkbox, uuid) {
        var checked = checkbox.checked;
        if(checked) {
            for(let i = 0; i < this.selectedWaypointUUIDs.length; i++) {
                let selectedUUID = this.selectedWaypointUUIDs[i];
                if(selectedUUID === uuid) {
                    return; 
                }
            }
            this.selectedWaypointUUIDs.push(uuid);
        } else {
            for(let i = 0; i < this.selectedWaypointUUIDs.length; i++) {
                let selectedUUID = this.selectedWaypointUUIDs[i];
                if(selectedUUID === uuid) {
                    this.selectedWaypointUUIDs.splice(i, 1);
                    return; 
                }
            }
        }
    }

    checkSaveEnabled() {
        if(this.selectedWaypointUUIDs && this.selectedWaypointUUIDs.length > 0) {
            $('#modalPrimaryButton').removeAttr("disabled"); //enable save button.
        } else {
            $('#modalPrimaryButton').attr("disabled", true); //disable save button.
        }
    }

    onSelectAll() {
        this.selectedWaypointUUIDs = new Array();
        if(this.waypoints) {
            var length = this.waypoints.length;
            for (var i = 0; i < length; i++) {
                var waypoint = this.waypoints[i];
                $('#'+waypoint.uuid + '_select').prop('checked', true);
                this.selectedWaypointUUIDs.push(waypoint.uuid);
            }
        }
        this.checkSaveEnabled();
    }

    onDeselectAll() {
        this.selectedWaypointUUIDs = new Array();
        if(this.waypoints) {
            var length = this.waypoints.length;
            for (var i = 0; i < length; i++) {
                var waypoint = this.waypoints[i];
                $('#'+waypoint.uuid + '_select').prop('checked', false);
            }
        }
        this.checkSaveEnabled();
    }
}

export default ShareWaypointsView;
